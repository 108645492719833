import React, {useCallback, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import moment from 'moment'
import axios from 'axios'
import {toast} from 'react-toastify'

export function SettingHoliday() {
  const [date, setDate] = useState<moment.Moment>(() => moment().startOf('month'))
  const [items, setItems] = useState<any>([])

  const handleDayClick = (current: moment.Moment) => {
    if (checkHoliday(current)) {
      removeHoliday(current)
    } else {
      addHoliday(current)
    }
  }
  const returnToday = () => setDate(moment().startOf('month'))
  const jumpToMonth = (num: number) => setDate(date.clone().add(num, 'month').startOf('month'))

  const getHoliday = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/calendar/holiday?start=${moment(date).add(-1, 'months').format('YYYYMMDD')}&end=${moment(date).add(1, 'months').format('YYYYMMDD')}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          toast.error(error)
        } else {
          setItems(result)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [date])

  useEffect(() => {
    getHoliday()
  }, [getHoliday, date])

  const addHoliday = (date: moment.Moment) => {
    console.log(date)
    let comment = window.prompt('코멘트를 적어주세요.', '')
    if (comment !== null && comment.length > 0) {
      const item: {date: string; comment: string} = {date: date.format('YYYY-MM-DD'), comment}
      axios
        .post(`${process.env.REACT_APP_API2_URL}am/calendar/holiday/add`, item)
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            const temps: any = [...items]
            temps.splice(0, 0, item)
            setItems(temps)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const removeHoliday = (date: moment.Moment) => {
    if (window.confirm(`${date.format('YYYY-MM-DD')} 날짜를 삭제하시겠습니까?`)) {
      const item: {date: string} = {date: date.format('YYYY-MM-DD')}
      axios
        .post(`${process.env.REACT_APP_API2_URL}am/calendar/holiday/delete`, item)
        .then(({data: {error, result}}) => {
          console.log(error)
          console.log(result)
          if (error !== '') {
            toast.error(error)
          } else {
            const holiday: any = checkHoliday(date)
            const temps: any = [...items]
            temps.splice(items.indexOf(holiday), 1)
            setItems(temps)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const checkHoliday: any = (date: moment.Moment) => {
    const dateText = date.format('YYYY-MM-DD')
    let holiday = null
    items.some((item: any) => {
      if (item.date === dateText) {
        holiday = item
        return true
      }
      return false
    })
    return holiday
  }

  function generate() {
    // 님 날짜 뭐 눌렀어요? (초기값은 오늘)
    const today = date

    // startOf('month') : 이번 달의 첫번 째 날로 설정 set to the first of this month, 12:00 am
    // week() : Week of Year. 이번 년도의 몇번째 주인가? => 3월 8일이면 10이겠죠?
    const startWeek = today.clone().startOf('month').week()

    // endOf('month').week() : 이번 달의 마지막 날로 설정 한 후 그것이 이번 년도의 몇번째 주인지 체크
    // 만약 이번 해의 첫번째 주(1월 1일이 속한 주)라면 53으로 세팅, 아니라면 그대로 유지
    // 이런 작업의 이유는 마지막 주가 첫 주가 될 수 없기 때문에 당연한 것임
    const endWeek = today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week()

    let calendar = []

    // 시작 주부터 마지막 주까지 +1 씩 증가시킴
    // 이제 주마다 일을 표기해야 하므로 len이 7인 arr를 생성 후 index를 기반으로 day를 표기하자
    for (let week = startWeek; week <= endWeek; week++) {
      calendar.push(
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          key={week}
        >
          {Array(7)
            .fill(0)
            .map((n, i) => {
              // 오늘 => 주어진 주의 시작 => n + i일 만큼 더해서 각 주의 '일'을 표기한다.
              let current = today
                .clone()
                .week(week)
                .startOf('week')
                .add(n + i, 'day')

              // 오늘이 current와 같다면 우선 '선택'으로 두자
              let isSelected = today.format('YYYYMMDD') === current.format('YYYYMMDD') ? 'selected' : ''

              // 만약, 이번 달이 아닌 다른 달의 날짜라면 회색으로 표시하자
              let isGrayed = current.format('MM') !== today.format('MM') ? 'grayed' : ''

              return (
                <div
                  style={{
                    width: '100%',
                    minHeight: 60,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  className={`${isSelected} ${isGrayed}`}
                  key={i}
                  onClick={() => handleDayClick(current)}
                >
                  <h5
                    style={{
                      marginTop: 10,
                      color: isGrayed ? '#AAA' : i === 0 ? '#F00' : i === 6 ? '#00F' : '#333',
                    }}
                  >
                    {current.format('D')}
                  </h5>
                  {checkHoliday(current) !== null && (
                    <div
                      style={{
                        height: 34,
                        paddingLeft: 8,
                        paddingRight: 8,
                        backgroundColor: '#FFF1F0',
                        borderRadius: 8,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          marginBottom: 0,
                          color: '#FF4B3E',
                        }}
                      >
                        {checkHoliday(current).comment}
                      </p>
                    </div>
                  )}
                </div>
              )
            })}
        </div>
      )
    }
    return calendar
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>휴일 달력</PageTitle>

      <div
        style={{
          padding: 20,
          borderRadius: 20,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            padding: 20,
            borderRadius: 20,
            backgroundColor: 'white',
            width: '100%',
          }}
        >
          <div className='col-12 shadow'>
            <div
              style={{
                padding: 40,
                paddingBottom: 10,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <h1 className='card-title'>
                <span className='title'>{date.format('YYYY-MM')}</span>
              </h1>
              <div>
                <label className={'btn btn-outline-primary'} onClick={() => jumpToMonth(-1)}>
                  <i className='fas fa-angle-left' style={{fontSize: 20}} />
                </label>
                <label style={{fontSize: 20}} className={'btn btn-outline-primary'} onClick={returnToday}>
                  오늘
                </label>
                <label className={'btn btn-outline-primary'} onClick={() => jumpToMonth(1)}>
                  <i style={{fontSize: 20}} className='fas fa-angle-right' />
                </label>
              </div>
            </div>
            <div style={{padding: 40}}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 20,
                }}
              >
                {['일', '월', '화', '수', '목', '금', '토'].map((el, index) => (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    key={el}
                  >
                    <h3
                      style={{
                        color: index === 0 ? '#F00' : index === 6 ? '#00F' : '#333',
                      }}
                    >
                      {el}
                    </h3>
                  </div>
                ))}
              </div>
              {generate()}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
