import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import axios from 'axios'
import Pagination from 'react-js-pagination'
import ImagePath from '../../../../commons/ImagePath'
import classNames from 'classnames'

export function MediaImagePlace() {
  const [items, setItems] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [status, setStatus] = useState('')
  const [search, setSearch] = useState('')

  const [totalCount, setTotalCount] = useState(0)
  const [productWaitCount, setProductWaitCount] = useState(0)
  const [productCount, setProductCount] = useState(0)
  const [productImageCount, setProductImageCount] = useState(0)
  const [showCount, setShowCount] = useState(0)

  const [wantPage, setWantPage] = useState(currentPage)

  useEffect(() => {
    getPlaceImages()

    return () => {}
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setWantPage(currentPage)
  }, [currentPage])

  const onTab = (tabStatus: string) => {
    setStatus(tabStatus)
    getPlaceImages(tabStatus, 1, search)
  }

  const onSearch = () => {
    setStatus(status)
    getPlaceImages(status, 1, search)
  }

  const handlePageChange = (page: number) => {
    getPlaceImages(status, page)
  }

  const getPlaceImages = (status = '', page = 1, search = '') => {
    axios
      .get(`${process.env.REACT_APP_API2_URL}am/image/place?page=${page}&status=${status}&search=${search}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
        } else {
          setItems(result.data.places)
          setTotalCount(result.data.totalCount)
          setProductCount(result.data.productCount)
          setProductWaitCount(result.data.productWaitCount)
          setProductImageCount(result.data.productImageCount)
          setShowCount(result.data.showCount)
          setCurrentPage(result.current_page)
          setPerPage(result.per_page)
          setTotal(result.total)
          window.scrollTo(0, 0)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const show = (item: any, show = 1) => {
    console.log('placeId : ' + item.id)
    console.log('show : ' + show)

    axios
      .put(`${process.env.REACT_APP_API2_URL}am/place/${item.id}/interior/show/${show}`)
      .then(({data: {error, result}}) => {
        console.log(error)
        console.log(result)
        if (error !== '') {
          alert(error)
        } else {
          alert('수정되었습니다.')
          //window.location.reload()
          const temps = [...items]
          temps.splice(temps.indexOf(item), 1, item)
          item.interior_show = show
          setItems(temps)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>장소 이미지</PageTitle>

      <div
        style={{
          padding: 20,
          borderRadius: 20,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div style={{width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
          <label
            onClick={() => {
              onTab('')
            }}
            className={classNames('btn', {'btn-dark': status === ''}, {'btn-btn-white': status !== ''})}
          >
            전체 장소 ({totalCount})
          </label>

          <label
            onClick={() => {
              onTab('productWait')
            }}
            className={classNames('btn', {'btn-dark': status === 'productWait'}, {'btn-btn-white': status !== 'productWait'})}
          >
            제품 등록된 대기 장소 ({productWaitCount})
          </label>

          <label
            onClick={() => {
              onTab('product')
            }}
            className={classNames('btn', {'btn-dark': status === 'product'}, {'btn-btn-white': status !== 'product'})}
          >
            제품 등록된 노출 장소 ({productCount}/{productImageCount})
          </label>

          <label
            onClick={() => {
              onTab('show')
            }}
            className={classNames('btn', {'btn-dark': status === 'show'}, {'btn-btn-white': status !== 'show'})}
          >
            인테리어 탭 미노출 장소 ({showCount})
          </label>
        </div>

        <div
          style={{
            marginTop: 20,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <input
            type='text'
            className='form-control'
            placeholder='장소 번호, 장소명'
            value={search}
            style={{flex: 1}}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                //onSearch()
              }
            }}
          />
          <label
            onClick={() => {
              onSearch()
            }}
            style={{width: 100}}
            className='btn btn-dark'
          >
            검색
          </label>
        </div>

        <div
          style={{
            padding: 20,
            borderRadius: 20,
            backgroundColor: 'white',
            width: '100%',
          }}
        >
          <div>
            {items.map((item: any, index: number) => {
              return (
                <div className='col-12' key={index.toString()}>
                  <div className='card card-custom card-stretch shadow mb-5'>
                    <div className='card-header'>
                      <h1 className='card-title'>
                        {item.id}. {item.title}
                      </h1>
                    </div>
                    <div className='card-body'>
                      <h3>{item.status}</h3>
                      <h3>{item.edited_at}</h3>
                      <div className={'row'}>
                        {item.place_image_arr.map((image: any) => {
                          if (image.image_path.length > 0) {
                            return (
                              <div key={`${image.image_path}`}>
                                <p>
                                  {image.image_path} {status}
                                </p>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                  <div>
                                    <div
                                      // onClick={() => {
                                      //   console.log(status)
                                      //   if (status === 'productWait') {
                                      //     window.open(`/media/image/wait/${image.id}`)
                                      //   } else {
                                      //     window.open(`/media/image/${image.id}`)
                                      //   }
                                      // }}
                                      style={{
                                        position: 'relative',
                                        margin: 4,
                                        padding: 4,
                                        width: 200,
                                        height: 200,
                                        overflow: 'hidden',
                                        border: 'solid 1px #CCC',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <img
                                        src={ImagePath.get(image.image_path, '100x100')}
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                          objectFit: 'contain',
                                        }}
                                        alt={ImagePath.get(image.image_path, '100x100')}
                                      />
                                      {/*{image.imageProductId > 0 && (*/}
                                      {/*  <img*/}
                                      {/*    src={'/images/image_product_badge.png'}*/}
                                      {/*    style={{*/}
                                      {/*      position: 'absolute',*/}
                                      {/*      top: 10,*/}
                                      {/*      left: 10,*/}
                                      {/*      width: 30,*/}
                                      {/*      height: 30,*/}
                                      {/*    }}*/}
                                      {/*  />*/}
                                      {/*)}*/}
                                    </div>
                                  </div>
                                  <div>
                                    <div
                                      style={{
                                        position: 'relative',
                                        margin: 4,
                                        padding: 4,
                                        width: 200,
                                        height: 200,
                                        overflow: 'hidden',
                                        border: 'solid 1px #CCC',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <img
                                        src={ImagePath.get(image.image_path, '2000x2000', 'inside')}
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                          objectFit: 'contain',
                                        }}
                                        alt={ImagePath.get(image.image_path, '2000x2000', 'inside')}
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <div
                                      style={{
                                        position: 'relative',
                                        margin: 4,
                                        padding: 4,
                                        width: 200,
                                        height: 200,
                                        overflow: 'hidden',
                                        border: 'solid 1px #CCC',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <img
                                        src={ImagePath.get(image.image_path, '550x364')}
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                          objectFit: 'contain',
                                        }}
                                        alt={ImagePath.get(image.image_path, '550x364')}
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <div
                                      style={{
                                        position: 'relative',
                                        margin: 4,
                                        padding: 4,
                                        width: 200,
                                        height: 200,
                                        overflow: 'hidden',
                                        border: 'solid 1px #CCC',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <img
                                        src={ImagePath.get(image.image_path, '500x500')}
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                          objectFit: 'contain',
                                        }}
                                        alt={ImagePath.get(image.image_path, '500x500')}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                          return <></>
                        })}
                      </div>
                    </div>
                    <div className='card-footer'>
                      <label
                        onClick={() => {
                          window.open(`${process.env.REACT_APP_WEB_URL}place/${item.id}`)
                        }}
                        className='btn btn-primary'
                      >
                        장소 보기
                      </label>

                      {item.interior_show === 1 && (
                        <label
                          style={{marginLeft: 20}}
                          onClick={() => {
                            show(item, 0)
                          }}
                          className='btn btn-danger'
                        >
                          인테리어 탭 숨김 처리
                        </label>
                      )}

                      {item.interior_show === 0 && (
                        <label
                          style={{
                            marginLeft: 20,
                            border: '1px solid #0056FF',
                            color: '#0056FF',
                          }}
                          onClick={() => {
                            show(item, 1)
                          }}
                          className='btn btn-outline-primary'
                        >
                          인테리어 탭 노출 처리
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <Pagination activePage={currentPage} itemsCountPerPage={perPage} totalItemsCount={total} pageRangeDisplayed={10} prevPageText={'‹'} nextPageText={'›'} onChange={handlePageChange} />
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4}}>
          <input
            value={wantPage}
            type='number'
            className='form-control'
            onChange={(e) => {
              try {
                setWantPage(parseInt(e.target.value))
              } catch (e) {}
            }}
          />
          <label
            onClick={() => {
              handlePageChange(wantPage)
            }}
            className='btn btn-dark'
            style={{width: 100}}
          >
            이동
          </label>
        </div>
      </div>
    </>
  )
}
